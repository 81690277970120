<template>
  <div class="header">
    <div class="Home-Left">
      <div class="logo">
        <router-link to="/"><img src="../assets/imgs/logo.png" /> </router-link>
        <p>数据更新至{{ time }}</p>
      </div>
      <div class="h-echarts">
        <div class="h-selct" v-show="showBtn">
          <a :href="this.linkTo">
            <el-button class="return-btn" size="small" icon="icon-return">返回驾驶舱</el-button>
          </a>
        </div>
        <div class="h-selct cascaders">
          <el-cascader class="cascaders-content" ref="cascaders" :props="props" :options="options" v-model="cascaders" @change="areaChange">
            <template slot-scope="{ node, data }">
              <div @click="nodeFocus">{{ data.label }}</div>
            </template>
          </el-cascader>
        </div>

        <div v-if="!this.$route.query.provinceId" class="h-selct logout" @click="logout">
        </div>
      </div>
    </div>
    <!-- 六大模式 -->
    <div class="idiot">
      <div
          class="box"
          ref="pageBox"
          v-for="(item, i) in listObj"
          :key="i"
          :class="item.path == $route.name ? 'active' : ''"
      >
        <router-link :to="item.path">
          <div class="box-left"></div>
          <div class="box-right">
            <div class="box-title">{{ item.newName ||item.name }}</div>
            <div class="box-home">
              <span>{{ item.value }}</span
              >{{ item.unit }}
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import { sixStatistics, cityList, getTime, getInitCitys} from "@/api/common";
// import { initCitys } from "@/api/config.js";
import {clone, cloneDeep} from "lodash"

export default {
  name: "Header",
  data() {
    let _this = this;
    return {
      initCitys: {
        provinceId:'',
        cityId:'',
        areaId:'',
      },
      provinceValue: "",
      provinceOptions: [],
      cityValue: "",
      cityOptions: [],
      areaValue: "",
      areaOptions: [],
      linkTo:'',
      listObj: [
        { name: "发卡商户",newName:'企业商户', value: "0", unit: "家", path: "HomePage" },
        { name: "预付交易", value: "0", unit: "笔", path: "HomeTrade" },
        { name: "资金监管", value: "0", unit: "元", path: "HomeSupervise" },
        { name: "投诉处理", value: "0", unit: "件", path: "HomeAcceptance" },
        { name: "风险预警",newName:'风险监测', value: "0", unit: "家", path: "HomePolice" },
        { name: "风险处置", value: "0", unit: "笔", path: "HomePlace" },
        { name: "预付合同", value: "0", unit: "份", path: "HomePrepaidcontract" },
        { name: "消费结构",newName:'消费人次', value: "0", unit: "人次", path: "HomeConsumptionstructure" },
        // { name: "疫情防控", value: "0", unit: "家", path: "HomeEpidemicpreventioncontrol" },
        { name: "任务协同", value: "0", unit: "件", path: "HomeTaskCoordination" },
        { name: "综合监管", value: "0", unit: "件", path: "HomeSynthesizeSupervise" },
        { name: "主体状态", value: "0", unit: "家", path: "HomeOther" }
      ],
      regionData: {
        regionId: 1,
        regionType: 0,
        regionName: "全国",
        cityName:'',
        areaName:'',
        flag:1
      },
      time: "2020.11.18 17:26",
      showBtn: true,
      regionLabel: {
        '国家': 0,
        '省|直辖市': 1,
        '市': 2,
        '区县': 3,
        '乡镇街道': 4,
        '综合体': 6,
        '社区、行政村':7,
        '商圈': 8,
      },
      disabled: false,
      paramLen:0,
      cascaders: [],
      props: {
        emitPath: true,
        lazy: true, //动态加载
        // expandTrigger: "hover",
        checkStrictly: true, //父子节点取消选中关联，从而达到选择任意一级选项
        value: "regionId",
        label: "regionName",
        disabled: "isDisabled",
        children:'childrens',
        lazyLoad (node, resolve) {
          // console.log("select====",node)
          _this.paramLen++;
          let id = 1,isInit=false,isRepeat=false;
          let query = _this.$route.query;
          if(!query.provinceId && localStorage.getItem("cityRegionId") && _this.paramLen === 1){
            id = localStorage.getItem("cityRegionId");
            isInit = true;  //是否不带参数且第一次加载
          }
          if(node.data && node.data.regionId){
            id = node.data.regionId;
          }

          if(!isInit && (id == localStorage.getItem("cityRegionId"))){
            isRepeat = true; //是否为节点重复加载
          }
          if(query.cityId && query.cityId == id){
            _this.cityName = node.data.regionName
          }
          if(query.areaId && query.areaId == id){
            _this.areaName = node.data.regionName
          }
          if(node.label === '乡镇街道'){
            // resolve(node.data.children)
            resolve()
          }else if(node.label === '商圈'){
            resolve()
          }else if(node.label === '综合体'){
            resolve()
          }
          else {
            // console.log("test=======")
            //console.log(node)
            // if(!isRepeat){
              _this.cityList(id - 0,node,(list)=>{
                // console.log(isRepeat,"isRepeat====")
                // console.log(list,"list====")
                if(!isRepeat){
                  resolve(list)
                }else{
                  resolve([])
                }
                if(!isRepeat && (_this.paramLen - 1 === _this.cascaders.length) && query.provinceId && _this.cascaders.length <= 3){
                  // console.log(456456)
                  _this.areaChange(_this.cascaders)
                }
              },isInit);
            // }else{
            //   resolve([])
            // }

          }
        }
      },
      options:[],
      parentNode:'',
    };
  },
  computed: {
    // costRequest() {
    //   return {
    //     regionId: this.regionData.regionId,
    //     regionType: this.regionData.regionType,
    //     flag: this.regionData.flag,
    //   };
    // },
  },
  watch: {
    // $route: {
    //   handler(val, oldVal) {
    //
    //   },
    //   deep: true, //增加deep 观察对象的子对象变化
    //   immediate:true,
    // },
    // costRequest: {
    //   handler(val, oldVal) {
    //     this.sixStatistics();
    //   },
    //   deep: true, //增加deep 观察对象的子对象变化
    // },


  },
  created() {
    sessionStorage.setItem("initParams",'initParams')
    let query = this.$route.query;
    if(query && query.provinceId){
      localStorage.setItem("cityRegionId",'')
      this.disabled = true;
      // console.log(query)
      if(query.provinceId == '0') {
        query.provinceId = "";
      }else{
        this.cascaders[0] = query.provinceId - 0;
      }
      if(query.cityId == '0') {
        query.cityId = ""
      }else{
        this.cascaders[1] = query.cityId - 0;
      }
      if(query.areaId == '0') {
        query.areaId = ""
      }else{
        this.cascaders[2] = query.areaId - 0;
      }
      window.sessionStorage.setItem('initCitys',JSON.stringify(query))
      this.initCitys = JSON.parse(window.sessionStorage.getItem('initCitys'))
      // this.initCitys = query
      // console.log(query)
      this.disabled = true;
      console.log(123456)
      if(this.initCitys.provinceId || this.initCitys.cityId || this.initCitys.areaId){
        // this.cascaders = [this.initCitys.provinceId - 0,this.initCitys.cityId - 0,this.initCitys.areaId - 0];
        // console.log(this.cascaders)
        this.intData();
      
      }
    }else{
    
      if(!localStorage.getItem("cityRegionId")){
        this.logout()
      }
      let userMenus = JSON.parse(localStorage.getItem("bigDataMenus"));
      // console.log(userMenus,'userMenus=========='
      window.sessionStorage.setItem('initCitys',"{}")
      let cityRegionId = localStorage.getItem("cityRegionId");
      this.cascaders = [cityRegionId - 0];
      this.intData();
      this.listObj = userMenus.map((v,i)=>{
        if(this.listObj[i].path == userMenus[i].url.replace("/",'')){
          return this.listObj[i]
        }
        // console.log(this.listObj[i].path)
        // console.log(userMenus[i].url)
        // return this.listObj[i]
      })
    
      // this.sixStatistics();
    
      // console.log("2222222222222222")
    }

  },
  methods: {
    intData() {
      // this.cityList(1);
      this.getTime();
    },
    sixStatistics() {
      sixStatistics({
          regionId: this.regionData.regionId,
          regionType: this.regionData.regionType,
          flag: this.regionData.flag
      }).then((res) => {
        // console.log("六个统计------》》》", res);
        //this.tableData = res.data;
        this.listObj.forEach((item, i) => {
          if (res.data[i].name == item.name) {
            item.value = Math.round(res.data[i].amount) || res.data[i].num;
          }
        });
      });
    },
    cityList(id,node,successFn,isInit) {
      // console.log(isInit,"------------")
      cityList({
        regionId: id,
      }).then((res) => {
        let zht = [];
        let street = [];
        let sq = [];
        let index = 0;
        res.data.nodeList.map((val,ind) => {
          val.leaf = false;
          val.value = val.regionId;
          val.label = val.regionName;
          val.flag = 1;
          val.isDisabled = this.initCitys.provinceId && (val.regionType === 0 || val.regionType === 1) ?
              this.disabled : this.initCitys.cityId && val.regionType === 2 ? this.disabled :
                  this.initCitys.areaId && val.regionType === 3 ? this.disabled : false;

          //如果带默认参数，参数的同级级设为叶子节点，下级不是叶子节点，参数对应节点不是叶子节点且最后一级不禁用。
          if(this.$route.query && this.initCitys.provinceId){
            val.leaf = true;
            if(this.initCitys.provinceId && this.initCitys.cityId && this.initCitys.areaId){
              if(val.regionType > 3){
                val.leaf = false;
              }
              if(this.initCitys.areaId == val.regionId){
                val.isDisabled = false
              }
            }else if (this.initCitys.provinceId && this.initCitys.cityId){
              if(val.regionType > 2){
                val.leaf = false;
              }
              if(this.initCitys.cityId == val.regionId){
                val.isDisabled = false
              }
            }else if (this.initCitys.provinceId){
              if(val.regionType > 1){
                val.leaf = false;
              }
              if(this.initCitys.areaId == val.regionId){
                val.isDisabled = false
              }
            }
          }
          if(val.regionId == this.initCitys.provinceId || val.regionId == this.initCitys.cityId || val.regionId == this.initCitys.areaId){
            index = ind
            val.leaf = false;
          }

          // val.disabled = disabled;
          if (val.regionType === 4) {
            val.leaf = false;
            street.push(val)
          } else if (val.regionType === 6) {
            val.leaf = true;
            zht.push(val)
          } else if (val.regionType === 8) {
            val.leaf = true;
            sq.push(val)
          } else if (val.regionType === 7) {
            val.leaf = true;
          }
          return val
        })
        let arrs = [];
        if (id === 1) {
          let option = [];
          option.push({
            regionType: '0',
            flag: 1,
            regionId: 1,
            value: 1,
            label:"全国",
            regionName: "全国",
            leaf: true,
            isDisabled: this.disabled
          })
          arrs = option.concat(res.data.nodeList)
        }else if (id !== 1 && node) {
          if (res.data.nodeList.length) {
            if (street.length || zht.length || sq.length) {
              arrs = [{
                regionId: id + '-4',
                value:id + '-4',
                label:"乡镇街道",
                regionName: "乡镇街道",
                regionType: 4,
                flag: 4,
                leaf: street.length ? false : true,
                childrens: street,
              }, {
                regionId: id + '-8',
                regionName: "商圈",
                value:id + '-8',
                label:"商圈",
                regionType: 8,
                flag: 8,
                leaf: sq.length ? false : true,
                childrens: sq,
              }, {
                regionId: id + '-6',
                regionName: "综合体",
                value:id + '-6',
                label:"综合体",
                regionType: 6,
                flag: 6,
                leaf: zht.length ? false : true,
                childrens: zht,
              }]
            } else {
              arrs = res.data.nodeList;
            }
          } else {
            node.leaf = true;
          }
        }
        let newArrs = [];
        if(isInit){
          // console.log("test333333333")
          let list = cloneDeep(arrs)
          newArrs = [{
            regionId: res.data.regionId,
            value: res.data.regionId,
            label: res.data.regionName,
            regionName: res.data.regionName,
            regionType: res.data.regionType,
            flag: 1,
            // leaf: list.length ? false : true,
            leaf: list.length ? false : true,
            childrens: list,
          }];
          // console.log(list)

          arrs = [];
          if(id === 1){
            arrs = list;
          }else{
            arrs = newArrs;
          }
          // console.log(this.regionData,"test444444")

          let flag = 1;
          if(res.data.regionType == 4 ||res.data.regionType == 6 ||res.data.regionType == 8 ){
            flag = res.data.regionType
          }
          this.regionData = {
            regionType: res.data.regionType,
            regionName: res.data.regionName,
            regionId: res.data.regionId,
            flag: flag,
            cityName:  res.data.regionType <3 ?  res.data.regionName : '',
            areaName: res.data.regionType <3 ?'' : res.data.regionName ,

          }

          sessionStorage.setItem("initParams", "initParams");
          this.sixStatistics()
          this.$emit("regionData",this.regionData)
        }
        if (successFn) {
          successFn(arrs,index)
        }
      })

    },
    areaChange(val){
      this.$nextTick(()=>{
        let emptyDom = document.getElementsByClassName("el-scrollbar__view el-cascader-menu__list is-empty")[0];
        // console.log(emptyDom)
        // console.log(this.parentNode)
        if(emptyDom){
          this.parentNode = emptyDom.parentNode.parentNode
          this.parentNode.style.display = 'none'
        }else if(this.parentNode){
          this.parentNode.style.display = 'block'
        }
        setTimeout(()=>{
          let input = this.$refs.cascaders.$children[0].$refs.input;
          input.style.width = 13 * (input.value.length - (val.length - 1) * 2) + 47 + 'px';
        },80)
      })

      this.$refs.cascaders.getCheckedNodes()[0].setHasChildren = false;
      let node = clone(this.$refs.cascaders.getCheckedNodes()[0])
      let regionId = val[val.length - 1];
      // console.log("areaChange",node)
      if(node.data.flag == 4 || node.data.flag == 6 || node.data.flag == 8 ){
        regionId = node.value.split("-")[0] - 0;
      }

      let regionPrevData = clone(this.regionData)
      let areaName = '';
      if(node.data.regionType >= 3){
        if(node.value.toString().indexOf('-') !== -1){
          areaName = node.pathLabels[node.pathLabels.length - 2]
        }else if(node.parent && node.parent.value.toString().indexOf('-') !== -1){
          areaName = node.pathLabels[node.pathLabels.length - 3]
        }
      }
      this.regionData = {
        regionType: node.data.regionType,
        regionName: node.data.regionName,
        regionId: regionId,
        flag: node.data.flag,
        cityName:  node.data.regionType <3 ?  node.data.regionName : '',
        areaName: areaName ? areaName  : node.data.regionType < 3 ? '' : node.data.regionName

      }

      //组件的值变化后关闭下拉框，并且把值传递出去。
      if((regionPrevData.regionId !== this.regionData.regionId) || (regionPrevData.regionName !== this.regionData.regionName)){
        setTimeout(()=>{
          this.$refs.cascaders.dropDownVisible = false;
        },300)
        this.sixStatistics()
        this.$emit("regionData",this.regionData)
      }

    },
    nodeFocus(){
      if(this.parentNode){
        this.parentNode.style.display = 'block'
      }
    },
    getTime() {
      getTime().then((res) => {

        this.time = res.data;
      });
    },
    //退出登录
    logout(){
      localStorage.setItem("bigDataMenus","[]")
      localStorage.setItem("permissions", '')
      localStorage.setItem("cityRegionId",'')
      this.$router.push({
        name: "Login",
      })
    }
  },
  components: {},
  mounted() {
    let password = window.localStorage.getItem("kangcunPass")

    let body = document.querySelector("body");
    body.className += 'diy-dropdown';
    let ind = window.REFERER_TO.indexOf(document.referrer);
    console.log(ind,'-----')
    if ( ind !== -1) {
      this.showBtn = true;
      if(window.location.search){
        this.linkTo = window.REFERER_TO[ind] + `#/home${window.location.search}`;
      }else{
        this.linkTo = window.REFERER_TO[ind] + `#/login?referer=true&kangcunName=${ window.localStorage.getItem("kangcunName")}&kangcunPass=${window.btoa(password)}`;
      }
    } else {
      this.showBtn = false;
    }
    // this.intData();
  },
};
</script>
<style lang="scss">
.return-btn {
  .icon-return {
    display: inline-block;
    float: left;
    margin-top: 6px;
    margin-right: 5px;
    width: 18px;
    height: 15px;
    background: url("../assets/imgs/icon-return.png") no-repeat;
  }
}

.diy-dropdown .el-cascader__dropdown {
  left: initial !important;
  right: 11px;
  & .popper__arrow{
    left: initial !important;
    right: 80px !important;
  }
}
</style>
<style lang="scss" scoped >


.header {
  .Home-Left {
    padding: 0 20px;
    background: url("../assets/imgs/logo-bg.jpg") no-repeat left top;
    background-size: 100%;
    overflow: hidden;
    position: relative;
    height: 100px;
    //min-width:1200px;
    .logo {
      float: left;
      position: relative;
      i {
        display: inline;
        width: 185px;
        height: 45px;
        background: url("../assets/imgs/logo-line.png") no-repeat;
        &:nth-child(1) {
          position: absolute;
          z-index: 0;
          top: -4px;
          left: 0;
        }
        &:nth-child(2) {
          position: absolute;
          z-index: 0;
          top: 40px;
          right: 0;
        }
      }
      img {
        width: 495px;
        height: 81px;
      }
      p {
        color: rgba(46, 190, 255, 0.8);
        font-size: 12px;
        margin-left: 25px;
        position: relative;
        top: -8px;
      }
    }
    .h-echarts {
      position: absolute;
      top: 25px;
      right: 0;
      .h-selct {
        float: left;
        //width: 400px;
        margin-right: 10px;
        &.cascaders{
          //width: 400px;
          .cascaders-content{
            &::v-deep .el-input__inner{
              min-width: 120px;
              width: 120px;
              //text-align: right;
              //border-color: transparent !important;
              //background-color: transparent !important;
            }
          }
        }

        &.logout{
          width: 47px;
          height: 47px;
          background: url(../assets/imgs/login/power-init.png) no-repeat center center;
          cursor: pointer;
          margin-top: -6px;
          &:hover{
            background: url(../assets/imgs/login/power.png) no-repeat center center;
          }
        }

        .return-btn {
          height: 28px;
          line-height: 28px;
          padding: 0 10px;
          background: rgba(46, 189, 255, 0.2);
          border: 1px solid #16518c;
          color: #33a7ff;
          .icon-return {
            display: inline-block;
            width: 15px;
            height: 15px;
            background: #fff;
          }
        }
      }
    }
  }
  // 六大模式
  .idiot {
    display: flex;
    flex-wrap: wrap;
    //margin: 25px 30px 30px 30px;
    max-width: 1200px;
    margin: 25px auto 30px;
    .box {
      width: 25%;
      height: 140px;
      display: flex;
      align-items: center;
      //opacity: 0;
      //transition: opacity .5s;
      .box-left {
        float: left;
        width: 127px;
        height: 140px;
        margin-left: 20px;
      }
      .box-right {
        float: left;
        text-align: left;
        line-height: 40px;
        margin-top: 30px;
        margin-left: -10px;
        .box-title {
          font-size: 16px;
          color: #eee;
        }
        .box-home {
          font-size: 14px;
          color: #fff;
          span {
            font-size: 28px;
            color: #5bd5f7;
            font-weight: bold;
            margin-right: 5px;
          }
        }
      }
    }
    .box:nth-child(1) {
      .box-left {
        background: url("../assets/imgs/header/icon1.png") no-repeat center center;
      }
    }
    .box:nth-child(2) {
      .box-left {
        background: url("../assets/imgs/header/icon2.png") no-repeat center center;
      }
    }
    .box:nth-child(3) {
      .box-left {
        background: url("../assets/imgs/header/icon3.png") no-repeat center center;
      }
    }
    .box:nth-child(4) {
      .box-left {
        background: url("../assets/imgs/header/icon4.png") no-repeat center center;
      }
    }
    .box:nth-child(5) {
      .box-left {
        background: url("../assets/imgs/header/icon5.png") no-repeat center center;
      }
    }
    .box:nth-child(6) {
      .box-left {
        margin-top:1px;
        background: url("../assets/imgs/header/icon6.png") no-repeat center center;
      }
    }
    .box:nth-child(7) {
      .box-left {
        background: url("../assets/imgs/header/icon7.png") no-repeat center center;
      }
    }
    .box:nth-child(8) {
      .box-left {
        background: url("../assets/imgs/header/icon8.png") no-repeat center center;
      }
    }
    .box:nth-child(9) {
      .box-left {
        background: url("../assets/imgs/header/icon9.png") no-repeat center center;
      }
    }
    .box:nth-child(10) {
      .box-left {
        background: url("../assets/imgs/header/icon10.png") no-repeat center center;
      }
    }
    .box:nth-child(11) {
      .box-left {
        background: url("../assets/imgs/header/icon11.png") no-repeat center center;
      }
    }
    .box:nth-child(12) {
      .box-left {
        background: url("../assets/imgs/header/icon12.png") no-repeat center center;
      }
    }
    .box:hover,
    .box.active {
      width: 25%;
      height: 140px;
      background: url("../assets/imgs/header/bg2.png") no-repeat center left;
      background-size: 100% 100%;
      //opacity: 1;
      .box-home {
        span {
          color: #5af4b1;
        }
      }
    }
    .box:hover:nth-child(1),
    .box.active:nth-child(1) {
      .box-left {
        background: url("../assets/imgs/header/icon1-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(2),
    .box.active:nth-child(2) {
      .box-left {
        background: url("../assets/imgs/header/icon2-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(3),
    .box.active:nth-child(3) {
      .box-left {
        background: url("../assets/imgs/header/icon3-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(4),
    .box.active:nth-child(4) {
      .box-left {
        background: url("../assets/imgs/header/icon4-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(5),
    .box.active:nth-child(5) {
      .box-left {
        background: url("../assets/imgs/header/icon5-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(6),
    .box.active:nth-child(6) {
      .box-left {
        background: url("../assets/imgs/header/icon6-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(7),
    .box.active:nth-child(7) {
      .box-left {
        background: url("../assets/imgs/header/icon7-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(8),
    .box.active:nth-child(8) {
      .box-left {
        background: url("../assets/imgs/header/icon8-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(9),
    .box.active:nth-child(9) {
      .box-left {
        background: url("../assets/imgs/header/icon9-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(10),
    .box.active:nth-child(10) {
      .box-left {
        background: url("../assets/imgs/header/icon10-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(11),
    .box.active:nth-child(11) {
      .box-left {
        background: url("../assets/imgs/header/icon11-hover.png") no-repeat center
        center;
      }
    }
    .box:hover:nth-child(12),
    .box.active:nth-child(12) {
      .box-left {
        background: url("../assets/imgs/header/icon12-hover.png") no-repeat center
        center;
      }
      .box-home {
        span {
          color: #5bd5f7;
        }
      }
    }
  }
}
</style>
