<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  watch:{
    '$router':function(val,oldVal){
      console.log(val)
    }
  },
}
</script>
<style lang="scss">

</style>
