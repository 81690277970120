import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    // redirect: "/Login",
    redirect: to => {

      if (window.location.search.indexOf('provinceId') !== -1) {
        return "/HomePage"
      } else {
        return "/Login"
      }

    },
    component: Home,
    children: [
      {
        path: "/Login",
        name: "Login",
        component: () => import("@/views/Login.vue")
      },
      {
        path: "/HomePage",
        name: "HomePage",
        component: () => import("@/views/HomePage.vue")
      },
      {
        path: "/HomeTrade",
        name: "HomeTrade",
        component: () => import("@/views/HomeTrade.vue")
      },
      {
        path: "/HomeSupervise", //资金监管
        name: "HomeSupervise",
        component: () => import("@/views/HomeSupervise.vue")
      },
      {
        path: "/HomeAcceptance",
        name: "HomeAcceptance",
        component: () => import("@/views/HomeAcceptance.vue")
      },
      {
        path: "/HomePlace",
        name: "HomePlace",
        component: () => import("@/views/HomePlace.vue")
      },
      {
        path: "/HomePolice",
        name: "HomePolice",
        component: () => import("@/views/HomePolice.vue")
      },
      {
        path: "/HomePrepaidcontract",
        name: "HomePrepaidcontract",
        component: () => import("@/views/HomePrepaidcontract.vue")
      },
      {
        path: "/HomeConsumptionstructure",
        name: "HomeConsumptionstructure",
        component: () => import("@/views/HomeConsumptionstructure.vue")
      },
      // {
      //   path: "/HomeEpidemicpreventioncontrol",
      //   name: "HomeEpidemicpreventioncontrol",
      //   component: () => import( "@/views/HomeEpidemicpreventioncontrol.vue")
      // },
      {
        path: "/HomeSynthesizeSupervise",
        name: "HomeSynthesizeSupervise",
        component: () => import("@/views/HomeSynthesizeSupervise.vue")
      },
      {
        path: "/HomeTaskCoordination",
        name: "HomeTaskCoordination",
        component: () => import("@/views/HomeTaskCoordination.vue")
      },
      {
        path: "/HomeOther",
        name: "HomeOther",
        component: () => import("@/views/HomeOther.vue")
      }
    ],
  },
  {
    path: "/Intelligent",
    name: "Intelligent",
    component: () => import("@/views/Intelligent.vue")
  },
  {
    path: "/IntelligentAnalysis",
    name: "IntelligentAnalysis",
    component: () => import("@/views/IntelligentAnalysis.vue")
  },
  {
    path: "/HomePdfDownload",
    name: "HomePdfDownload",
    component: () => import("@/views/HomePdfDownload.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404"),
    hidden: true
  },


];

const router = new VueRouter({
  routes,
  mode: 'history',
  // base: "/fy/report/", //富阳政务云
  // base: "/report/", //甘肃政务云
  // base: "/vr/"//两江
});

function parseSearchArgs() {
  var url = window.location.search; //获取url中"?"符后的字串
  var rst = {};
  if (url.indexOf("?") != -1) {
    var str = url.substr(1);
    var parts = str.split("&");
    for (var i = 0; i < parts.length; i++) {
      rst[parts[i].split("=")[0]] = decodeURI(parts[i].split("=")[1]);
    }
  }
  return rst;
}



router.beforeEach((to, from, next) => {
  if (to.path == "/IntelligentAnalysis") {
    return next()
  }
  if (to.path === "/PdfDownload") {
    console.log(11111)
    return next()
  }
  let params = parseSearchArgs();
  let initUrl = {};
  if (params.provinceId) {
    // console.log(10)
    window.sessionStorage.setItem('initUrl', JSON.stringify(params))
    initUrl = JSON.parse(window.sessionStorage.getItem('initUrl')) || false;
    if (to.query.provinceId === initUrl.provinceId) {
      // console.log(11)
      next()
    } else {
      // console.log(22)
      next({
        path: to.path,
        query: initUrl
      })
    }
  } else {

    if (initUrl && initUrl.provinceId) {
      if (to.query.provinceId === initUrl.provinceId) {
        next()
      } else {
        next({
          path: to.path,
          query: initUrl
        })
      }
    } else {
      // console.log(22222)
      // console.log(to)
      let permissions = window.localStorage.getItem("permissions")
      // console.log(permissions)
      if (to.path !== "/Login") {
        if (!permissions || permissions !== (new Date().toLocaleDateString())) {
          next({
            name: "Login",
            params: {
              noPass: true
            }
          })
        } else {
          next()
        }
      }
      next()
      // else{
      //     next()
      //   }

    }

  }

})

//解决没有权限不跳转时报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  undefined
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

export default router;
