// const interLigendata = function (city) {

//     let data = [];
//     if (city.indexOf("上海市") !== -1) {
//         data = [1, 2, 3, 4, 5, 6]
//     } else if (city.indexOf("杭州市") !== -1) {
//         data = [1, 2, 3, 4, 5, 6, 7, 8, 9]
//     }

//     return { data }
// }

// export {
//     interLigendata
// }

var tools = {}
tools.interLigendata = function (city) {
    let data = [];
    if (city.indexOf("上海市") !== -1) {
        data = [1, 2, 3, 4, 5, 6]
    } else if (city.indexOf("杭州市") !== -1) {
        data = [1, 2, 3, 4, 5, 6, 7, 8, 9]
    }

    return { data }
}
tools.install = function (Vue,options) {
    Vue.prototype.$tools = tools
    Vue.tools = tools
}
export default tools