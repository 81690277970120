import Vue from 'vue'
import * as echarts from 'echarts';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/css/reset.css'
import './assets/css/_diy-element.scss'
import './assets/css/diy.scss'
import tools from './intergendata/inter.js';

Vue.prototype.$echarts = echarts;

Vue.use(ElementUI, { size: 'small', zIndex: 5000 });
Vue.config.productionTip = false

Vue.use(tools)


Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'watchStorage') {

      // 创建一个StorageEvent事件
      var newStorageEvent = document.createEvent('StorageEvent');
      const storage = {
          setItem: function (k, val) {
              sessionStorage.setItem(k, val);

              // 初始化创建的事件
              newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

              // 派发对象
              window.dispatchEvent(newStorageEvent)
          }
      }
      return storage.setItem(key, newVal);
  }
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
