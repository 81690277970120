<template>
  <div class="home">
    <StartPage v-if="showStartPage"></StartPage>
    <Header  @regionData="regionData" v-if="$route.name !== 'PdfDownload' && $route.name !== 'Login'"></Header>
    <router-view :change="change" :regionName="regionName" :cityName="cityName" :areaName="areaName" :flag="flag" :regionId="regionId" :regionType="regionType"></router-view>
  </div>
</template>

<script>
import Header from "@/components/Header"
import StartPage from "@/components/StartPage.vue";
export default {
  name: 'Home',
  data(){
    return {
      regionName:'全国',
      cityName:'',
      areaName:'',
      flag: 1,
      regionId:'1',
      regionType:'0',
      showStartPage:false ,
      change:0,
    }
  },
  components: {
    Header,
    StartPage
  },
  watch: {
    $route: {
      handler(val, oldVal) {

        // console.log(sessionStorage.getItem("initParams"))
      },
      deep: true, //增加deep 观察对象的子对象变化
      immediate:true,
    },
  },
  created(){

    if((window.location.search.indexOf("provinceId") !== -1) || (window.location.search.indexOf("referer") !== -1)){
      this.showStartPage = false;
    }else{
      this.showStartPage = true;
    }

  },
  mounted() {
    setTimeout(()=>{
      this.showStartPage = false;
    },5000)
  },
  methods:{
    regionData(data){
      // console.log(data,"test1111111111111")
      let name = data.regionName;
      // if(data.regionName === '中国'){
      //   name = '全国'
      // }
      if(data.regionId){
        this.cityName= data.cityName;
        this.areaName= data.areaName;
        this.regionName = data.regionName;
        this.flag = data.flag || 1;
        this.regionId = data.regionId;
        this.regionType = data.regionType;
        this.change++;
      }
      // else{
      //   this.regionId = 1;
      //   this.regionType = "0";
      //   this.regionName = "全国";
      //   this.cityName= "";
      //   this.areaName= "";
      //   this.flag = 1;
      // }

    }
  }
}
</script>
